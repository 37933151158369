<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 08:20:39
-->
<template>
    <div class="majorproduct-container">
  
      <div class="submenu-wrapper">
        <Submenu 
        :engLabel="englabel"
        :cnLabel="cnLabel"
        :linkData="linkData"
      />
      </div>
  
      <div class="main-wrapper py-2 py-md-5 px-2">
          <!-- 产品总览 -->
          <div class="img-wrapper">
          <p class="split-header">主要产品</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="img"
                :height="imgHeight"
                fit="fill"
                />
            </div> 
         </div>
        </div>


        <!-- 产品详情 -->
        <div class="product-details">
          <p class="split-header">产品详情</p>
          <div class="row justify-content-start justify-content-md-center mt-3">
             
             <div class="col-12 col-md-3 card-wrapper2 my-2 mx-my-4 mx-1 mx-md-4" v-for="item in ProductData" :key="item.key">
               <div class="product-img border my-2 my-md-4">
                <ImagePreview
                  :src="item.url"
                  :height="188"
                  :width="188"
                  :previewurl="getPreviewurl(item.url)"
                  fit="fill"
                />
               </div>
    

                
                <h4 class="product-title">
                  {{ item.title }}({{ item.key }})
                </h4>
                <div class="details-text">
                  <p>
                    <span>功能配置：</span>
                    {{ item.productConfig }}
                  </p>
                  <p>
                    <span>支持：</span>
                    {{ item.support }}
                  </p>
                  <p>
                    <span>配套主机厂：</span>
                    {{ item.client }}
                  </p>
                </div>
             </div>
         </div>
        </div>


  
      </div>
  
    </div>
  </template>
  
  <script>
  import { ProductLink } from "@/assets/js/submenulink.js";
  import { getProductDetails } from "@/api/product.js";
  import Submenu from "@/components/Submenu/";
  import ImageLoader from "@/components/ImageLoader/";
  import ImagePreview from "@/components/ImagePreview";

  export default {
     components: {
      Submenu,
      ImageLoader,
      ImagePreview,
     },
    
     data() {
      return {
        englabel: 'PRODUCT',
        cnLabel: '产品',
        imgHeight: 568,
        img_aspect_ratio: (1344 / 640).toFixed(2),
        ProductData:[],//产品数据
        windowSize: {},
        linkData: [],
        img: require("@/assets/img/Product/product.png"),

      }
    },
  
    created() {
        this.getWindowSize();
        this.linkData = ProductLink;
        this.getProductData();
     },
  

    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      getProductData() {
        getProductDetails().then(res => {
              this.ProductData = res;
            });
        },

      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
        },
        getPreviewurl(item){
         return [item];
       },
     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
  @import "~@/styles/mixin.less";
    .majorproduct-container{
      position: relative;;
      width: 100%;;
      height: auto;
      .general-layout;
  
        .product-details{
          margin:3rem 1rem;
          .product-title{
            text-align: center;
            font-weight: bold;
            margin-bottom: 1rem;
          }

          .product-img{
            width: 100%;
            text-align: center;
            .image-preview-container{
                display: inline-block;
            }
          }

          .details-text span{
            font-weight: 700;
          }
        }
  
    }
  
  </style>