/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-25 08:19:52
 */
import Mock from "mockjs"

Mock.mock("/index.php/product-details","get",{
    code: 0,//错误码
    msg: "",//错误消息
    //data为具体的消息内容，如果code不为0，则必为null
    data: [
        {   
            id:1,
            key:'ACU',
            url:  require("@/assets/img/Product/ACU.png"),
            title:'安全气囊控制器',
            productConfig: ` 支持4~24个独立的带储能的点火回路1.2A@2ms
            支持16个以内外围传感器，采用PSI5总线。
            满足EDR（A+B）功能法规要求`,
            support: 'CAN、CANFD、UDS、OTA',
            client: `奔腾、长安、吉利商用车、东风小康、东风风神、江淮、华晨鑫源、合众、零跑、爱驰、金康、捷途、江铃新能源、江西五十铃、天际、大运、太行长青、金安世腾、百度阿波罗、吉祥智驱等`
        },

        {   
            id:2,
            key:'APM',
            url:  require("@/assets/img/Product/APM.png"),
            title:'纹波防夹控制器',
            productConfig: ` 防夹、PWM调速防夹、远程控制、语音控制、延长机构寿命等；
            满足法规要求： GB11552-2009、美标FMVSS118、欧标74/60/EEC`,
            support: 'LIN、CAN、UDS、OTA',
            client: `奔腾、长安、吉利、日产启辰、东风风行、东风风神、东风小康、江淮、长安欧尚、华晨、东南、江铃、奇瑞、海马、云度、潍柴、等`
        },

        {   
            id:3,
            key:'DCU',
            url:  require("@/assets/img/Product/DCU.png"),
            title:'车门控制器',
            productConfig: `车窗防夹、调速车窗、后视镜调节/记忆、门锁、隐藏门把手、门灯、方向盘座椅记忆联调、远程控制、语音控制、位置反馈、延长机构寿命等`,
            support: 'LIN、CAN、UDS、OTA',
            client: `红旗、吉利、长城、奔腾、东风风行、日产启辰、理想、等`
        },

        {   
            id:4,
            key:'PLGM',
            url:  require("@/assets/img/Product/PLGM.png"),
            title:'电动尾门控制器',
            productConfig: ` 支持单、双撑杆控制、PWM调速、防夹、悬停、支持多种吸合锁控制、高度设置、声音提醒、平顺静音控制。`,
            support: `LIN、CAN、UDS、OTA。`,
            client: `比亚迪、红旗、长城、五菱、东风柳汽、东风小康、金康、威马、捷途、等`
        },

        {   
            id:5,
            key:'PLGS',
            url:  require("@/assets/img/Product/PLGS.png"),
            title:'脚踢传感器',
            productConfig: ` 感应距离：≥30CM（可按需求调节参数）
            技术亮点：双感应天线、优秀的动作识别算法感应灵敏、防止误触发、功耗低＜0.2mA、百余种标定工况数据积累。`,
            support: `LIN、硬线输出、软件升级。`,
            client: `长城、比亚迪、smart、五菱、东风柳汽、江淮、合众、理想、捷途、等`
        },

        {   
            id:6,
            key:'DSCU',
            url:  require("@/assets/img/Product/DSCU.png"),
            title:'座椅控制器',
            productConfig: ` 纹波位置识别、支持12向输出、2路通风、2路加热、满足26262功能安全等级B、座椅高度/水平/靠背/腿托、方向盘调节/记忆等功能。`,
            support: `LIN、CAN、UDS、OTA.`,
            client: `红旗、奔腾、日产启辰、吉利、长城、理想、东风柳汽、等`
        },


        {   
            id:7,
            key:'APL',
            url:  require("@/assets/img/Product/APL.png"),
            title:'氛围灯控制器',
            productConfig: ` 支持A2B和硬线的音频接口、支持硬线、LIN、CAN、SeddLED接口灯头、256色全彩、音乐律动、单色静态，单色呼吸，变色呼吸，全色呼吸，七彩模式等多种模式可选、掉电记忆。`,
            support: 'LIN、CAN、UDS、OTA',
            client: `吉利、长城、红旗、小鹏、海马、捷途、等`
        },

        {   
            id:8,
            key:'EWP',
            url:  require("@/assets/img/Product/EWP.png"),
            title:'电动水泵控制器',
            productConfig: ` 安装于水泵电机外壳体，与水泵接插件插针和定子插针焊接后通过限位柱和自攻螺钉固定。 
            用于电动水泵的启停保护。使得电动水泵正常工作`,
            support: 'LIN、CAN、UDS、OTA',
            client: `比亚迪平台`
        },

        {   
            id:9,
            key:'AFS/ALS',
            url:  require("@/assets/img/Product/AFS-ALS.png"),
            title:'自适应前照明系统',
            productConfig: `支持静态/动态高度调节、支持车载荷变化、加减速变化、坡道修正补偿功能、支持路况波动识别、支持车速自适应调整功能、支持模拟和数字高度传感器、集成灯光驱动控制`,
            support: 'LIN、CAN、UDS、OTA',
            client: `红旗平台`
        },

        {   
            id:10,
            key:'EDM',
            url:  require("@/assets/img/Product/EDM.png"),
            title:'电动车门控制器',
            productConfig: `电动开关门、自动吸合、悬停、防撞、后方来车预警、防夹、手动助理、随动关门、防爆力开门、防风、坡度自动补偿、开度自定义、速度自定义、破冰功能、软件热保、舒适关门、OTA、信息安全、功能安全等`,
            support: `LIN、CAN、UDS、OTA`,
            client: `阿维塔`
        },

        {   
            id:11,
            key:'PSD',
            url:  require("@/assets/img/Product/PSD.png"),
            title:'电动滑门控制器',
            productConfig: `门外/门内、前排按钮、遥控、语音开/关门，自动上锁解锁，中途停止，坡道保持/自适应，防夹，防夹保持，调速/加速/速度档位选择，行驶中防开，制动，破冰解锁，电压检测，报警`,
            support: `LIN、CAN、UDS、OTA`,
            client: `长城、比亚迪、smart、五菱、东风柳汽、江淮、合众、理想、捷途、等`
        },

        {   
            id:12,
            key:'360',
            url:  require("@/assets/img/Product/360.png"),
            title:'360环视控制器',
            productConfig: ` 支持静态/动态高度调节、支持车载荷变化、加减速变化、坡道修正补偿功能、支持路况波动识别、支持车速自适应调整功能、支持模拟和数字高度传感器、集成灯光驱动控制`,
            support: `LIN、CAN、UDS、OTA.`,
            client: `红旗平台`
        },


    ]
})