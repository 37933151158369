/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-24 09:02:32
 */
import axios from "axios";

const ins = axios.create(); // 创建一个axios的实例
ins.interceptors.response.use(function(resp) {
  if (resp.data.code !== 0) {
    console.log("获取数据失败")
    return null;
  }
  return resp.data.data;
});

export default ins;
