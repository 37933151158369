<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-20 08:14:07
-->
<template>
  <div class="profacility-container">

    <div class="submenu-wrapper">
      <Submenu 
      :engLabel="englabel"
      :cnLabel="cnLabel"
      :linkData="linkData"
    />
    </div>

    <div class="main-wrapper py-2 py-md-5 px-2">

  
          <!-- 生产流程 -->
          <div class="img-wrapper">
            <p class="split-header">生产流程</p>
            <div class="row justify-content-center">
              <div class="col-12 col-xl-10">
                <ImageLoader 
                  :src="img"
                  :height="imgHeight"
                  fit="fill"
                  />
              </div> 
           </div>
          </div>

          <!-- 生产能力 -->
          <div class="production-capacity">
            <p class="split-header">生产能力</p>

            <div class="row justify-content-start justify-content-md-center">

              <!-- 潜江基地 -->
              <div class="col-12 col-xl-5 my-2 my-md-5 mx-1 mx-md-5" v-for="item,index in capacityData" :key="index">
                 <div class="row">
                    <div class="col-12">
                      <ImagePreview 
                        :src="item.maxImg"
                        :height="288"
                        :previewurl="getPreviewurl(item.maxImg)"
                        fit="fill"
                        />
                    </div>
                    <div class="col-12 col-xl-6" v-for="items,sub in item.imgs" :key="sub">
                      <ImagePreview 
                        :src="items"
                        :height="144"
                        :previewurl="getPreviewurl(items)"
                        fit="fill"
                        />
                    </div>

                    <div class="my-4">
                      <h3>{{item.title}}</h3>
                      <p>{{ item.introduce }}</p>
                    </div>
                 </div>

              </div> 

           </div>

          </div>


    </div>

  </div>
</template>

<script>
import { AboutLink } from "@/assets/js/submenulink.js";
import Submenu from "@/components/Submenu/";
import ImageLoader from "@/components/ImageLoader/";
import ImagePreview from "@/components/ImagePreview/";
export default {
   components: {
    Submenu,
    ImageLoader,
    ImagePreview,
   },
  
   data() {
    return {
      englabel: 'ABOUT US',
      cnLabel: '关于东久电子',
      imgHeight: 568,
      img_aspect_ratio: (1179 / 532).toFixed(2),
      windowSize: {},
      linkData: [],
      capacityData:[
        {
          title:'湖北潜江基地',
          introduce:`占地100亩,一期50亩年产能600万套。二期50亩(在建)`,
          maxImg:require("@/assets/img/About/shengchansheshi/qianjiangjidi.png"),
          imgs:[
            require("@/assets/img/About/shengchansheshi/qianjiangjidi2.png"),
            require("@/assets/img/About/shengchansheshi/qianjiangjidi3.png"),
          ]
        },
        {
          title:'河北石家庄基地',
          introduce:`占地31亩,年产能600万套`,
          maxImg: require("@/assets/img/About/shengchansheshi/shijiazhuangjidi.png"),
          imgs:[
            require("@/assets/img/About/shengchansheshi/shijiazhuangjidi2.png"),
            require("@/assets/img/About/shengchansheshi/shijiazhuangjidi3.png"),
          ]
        }
      ],

      img: require("@/assets/img/About/shengchansheshi/shengchanliucheng.png"),

    }
  },

  created() {
      this.getWindowSize();
      this.linkData = AboutLink;
   },


  mounted() {
      //拉伸窗口时触发
      window.onresize = () => {
          this.getWindowSize();
          
      }

  },
   methods: {
    getPreviewurl(item){
         return [item];
       },
    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
        },
   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

  .profacility-container{
    position: relative;;
    width: 100%;;
    height: auto;
    .general-layout;

      .profacility-profile{
        padding: 0 10px;
        margin: 46px 0;
        & .image-loader-container{
          width: 188px;
        }

      }





    
  }

</style>