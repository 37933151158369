<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-17 11:58:28
-->
<template>
  <div class="header-container container-fluid">
    <header class="navbar d-sm-flex header-wrapper align-items-center justify-content-between justify-content-md-center" >
		            
		<a href="/" class="navbar-brand"><img src="../../assets/img/logo.png" alt="" width="56"></a>
		<a href="#" class="btn-sidebar hidden-sm-and-up" @click="handleClick($event)">
		<MenuIcon 
		:isMobileNavShow="iconTypeState"
		:hasMobileNavOpened="notFirstClick"
		/>
		<!-- <Icon :type="iconTypeState?'icon-menu':'icon-guanbi'" class="hidden-sm-and-up"  /> -->
		</a>	
		<Navbar class="hidden-xs-only" />
	</header>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Icon from "@/components/Icon";
import MenuIcon from "@/components/MenuIcon";
export default {
   components: {
	Navbar,
	Icon,
	MenuIcon,
   },
   props: {
	  iconType: {
		type: Boolean,
		default: true,
	  }
   },

   computed: {
	  iconTypeState(){
        this.iconClass = this.iconType?this.iconType:this.iconClass;
		return this.iconClass;
	  }
   },
   data(){
       return {
		  iconClass: true,//控制icon图标的样式
		  notFirstClick: false,//是否第一次点击
	   }
   },
   methods: {
	handleClick() {
		this.iconClass = !this.iconClass;
		this.notFirstClick = true;
		this.$emit("clickIconBtn","");
	},
	transIconClass(){
		this.iconClass = !this.iconClass;
	}
   }

}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

.header-wrapper{
	height: @header-height;
	border-bottom: 1px solid #DBDCDD;
}

.navbar a{
	color:@words !important;
}

.icon-menu,
.icon-guanbi{
	font-size: 2rem;
}

.btn-sidebar:hover
{
	color: @primary;
}


.icon-menu:hover,
.icon-guanbi:hover
{
	color: @primary;
}


@media (max-width: 767.8px){
	.header-wrapper{
		 padding: 0 16px !important;

		 &.btn-sidebar{
			&:hover{
				color:@hoverLink !important;
			}
		 }
	}
}




</style>