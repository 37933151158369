<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 08:20:39
-->
<template>
  <div class="cominf-container">

    <div class="submenu-wrapper">
      <Submenu 
      :engLabel="englabel"
      :cnLabel="cnLabel"
      :linkData="linkData"
    />
    </div>

    <div class="main-wrapper">

      <!-- 资讯列表 -->
      <div class="news-list-wrapper" v-if="newsListHide">
            <div class="news-wrapper">
              <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-xl-8 newsbox">
                    <ul class="news-list-ul">
                      <a :href="item.iframe_url" class="news-link" v-for="item,index in newsList" :key="index" target="_blank">
                        <li class="news-list-li px-2">
                            <div class="row news-list-box pd-md-3 align-items-center">
                              <div class="col-12 col-md-4 px-md-5 my-2 my-md-4">
                                <ImageLoader 
                                  :src="item.url"
                                  :height="138"
                                  fit="fill"
                                />
                              </div>
                              <div class="col-12 col-md-8  my-2 my-md-4 ">
                                <h3>{{ item.title }}</h3>
                                <p class="padding-right-2">
                                {{ item.abstract }}
                                </p>
                                <span>发布时间: {{ item.release_time }}</span> <span style="margin-left:2rem">( 资讯摘自于</span><span style="color:#1A73E8; margin:0 0.3rem">{{ item.source }}</span>)
                              </div>
                            </div>
                        </li>
                      </a>
                    </ul>
                </div>
              </div>

              <div v-if="newsListLeng > pageSize" class="row justify-content-center">
                <div class="col-12 col-md-10 col-xl-8 ">
                  <div class="pagination">
                      <el-pagination
                          background
                          layout="total, prev, pager, next"
                          :current-page="pageIndex"
                          :page-size="pageSize"
                          :total="newsListLeng"
                          @current-change="handlePageChange"
                      ></el-pagination>
                  </div>

                </div>
                </div>
          </div>

      </div>



      <!-- 资讯主体 -->
      <div class="news-abstract-wrapper py-1 py-md-3" v-if="!newsListHide">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 col-xl-6 col-xl-8 ">
            <iframe :src="this.newsAbstract.iframe_url" frameborder="0"></iframe>
             <!-- <div class="title-wrapper">
                <div class="row justify-content-center">
                   <div class="col-12 col-md-8 my-3">
                    <h3>{{newsAbstract.title}}</h3>
                   </div>
                   <div class="col-12 col-md-6 subtitle">
                     <p>{{ newsAbstract.subtitle }}<span class="mx-md-3">(发布时间: {{ newsAbstract.release_time }})</span></p>
                   </div>
                </div>
             </div>

             <div class="abstract-body">
                <ul class="row">
                  <li v-for="item,index in newsAbstract.main_body_data" :key="index">

                     <div v-if="item.type=='text'" class="col-12 mt-md-5" >
                      <p>
                       {{ item.center }}
                    </p>
                     </div>

                     <div v-else class="col-12 col-sm-10 col-md-8 col-xl-6 my-1 mt-md-4 abstract-img">
                      <ImagePreview
                        :src="item.center"
                        :height="388"
                        :previewurl="getPreviewurl(item.center)"
                        fit="fill"
                      />
                    </div>

                  </li>

                </ul>
             </div> -->
          </div>

          <div class="col-12 col-md-8 col-xl-6 col-xl-8 news-page">
              <div class="row">
                 <div class="col-4 col-md-3 page-up" >
                  <span class="direction-label">上一篇:</span>
                  <span v-if="listSub===0" class="mx-2">无</span>
                  <a v-else href="javascript:void(0);" @click="getNewsBody(listSub-1)">
                    {{ newsList[listSub-1].title }}
                  </a>
                 </div>
                 <div class="col-4 col-md-6 to-newsList">
                   <a @click="toNewsList()">回到列表</a>
                 </div>
                 <div class="col-4 col-md-3 next-page">
                    <span v-if="listSub===newsList.length-1" class="mx-2">无</span>
                    <a v-else href="javascript:void(0);" @click="getNewsBody(listSub+1)">
                    {{ newsList[listSub+1].title }}
                    </a>
                    <span class="direction-label">
                      下一篇
                    </span>
                 </div>
              </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { NewsArticleLink } from "@/assets/js/submenulink.js";
import { getNewsIndList } from "@/api/news.js";
import Submenu from "@/components/Submenu/";
import ImageLoader from "@/components/ImageLoader/";
import ImagePreview from "@/components/ImagePreview";
export default {
   components: {
    Submenu,
    ImageLoader,
    ImagePreview,
   },
  
   data() {
    return {
      englabel: 'News',
      cnLabel: '资讯',
      newsListHide: true,//资讯列表和主体部分的切换
      windowSize: {},
      linkData: [],
      newsList: [],//资讯列表
      newsListLeng: 0,
      newsAbstract:[],//资讯主体
      listSub: 0,//选中的资讯
      pageIndex: 1,
      pageSize: 8,
      img: require("@/assets/img/News/newsImg.png"),
    }
  },

  created() {
      this.getWindowSize();
      this.linkData = NewsArticleLink;
      this.getNewsData();

   },

  mounted() {
      //拉伸窗口时触发
      window.onresize = () => {
          this.getWindowSize();
          
      }

  },
   methods: {
    // 得到新闻数据
    getNewsData() {
      getNewsIndList().then(res => {
            this.newsList = res.reverse();
            this.newsListLeng = res.length;
          });
      },

    // 获取可视区窗口大小
    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
      },

    //获取新闻主体数据
    getNewsBody(sub){
        this.listSub = sub;
        this.newsAbstract = this.newsList[sub];
        console.log(this.newsAbstract);
        this.newsListHide = false;

    },

    toNewsList(){
      this.newsListHide = true;
    },

    getPreviewurl(item){
       return [item];
     },

    // 分页导航
     handlePageChange(val) {
        this.pageIndex = val;
        console.log(this.pageIndex);
      },
   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
  .cominf-container{
    position: relative;;
    width: 100%;;
    height: auto;

    .main-wrapper{
      position: relative;;
      width: 100%;
      margin-top: 3rem;
      height: auto;
      .news-wrapper{
        position: relative;
        margin: 2rem 1rem;
        height: auto;

        .pagination{
          margin:1rem 0;
          justify-content: end;
        }
        .newsbox{
          // width: 100%;
          height: auto;
          .news-list-ul{
            width: 100%;
            height: auto;
            padding: 0;

     

            .news-list-li{
              height: auto;
              width: 100%;
              margin-top: 2rem;
              background-color: #F7F7F7;
              color: @words;
              &:hover{
                color: @hoverLink;
              }
        
            }

            .news-link:hover{
              color: @hoverLink;
            }
          }
        }
   
      }

      .news-abstract-wrapper{
        position: relative;
        margin: 2rem 1rem;
        height: auto;
        p{
          text-indent: 2rem;
        }

        li{
          width: 100%;
        }
        .title-wrapper{
          text-align: center;

          .subtitle{
            padding-right: 1rem;
            text-align: end;
          }
        }

        .abstract-img {
           margin: 0 auto;
        }


        .news-page{
          padding: 2rem 0;
          border-top: 1px solid @lightWords;

          .direction-label{
            font-weight: 700;
          }

          a{
            color:@primary;
            &:hover{
              color: @selectedLink;
              cursor:pointer;
            }
          }
          .next-page{
            text-align: end;
          }

          .to-newsList{
            text-align: center;
          }
        }
      }


    }
  }

</style>