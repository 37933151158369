/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-24 08:37:19
 */
import Mock from "mockjs"

// 所有的服务端接口在接收到请求后，均响应以下JSON格式

Mock.mock("/index.php/jobs-data","get",{
    code: 0,//错误码
    msg: "",//错误消息
    //data为具体的消息内容，如果code不为0，则必为null
    data: [
        {   
            id:1,
            jobTitle: '软件工程师 ( 储备 )',
            jobType: '研发技术',
            workplace: '上海',
            county: '浦东新区',
            jobCategory: '全职',
            releaseTime: '2023-07-25',
            jobDescription: [
                "在指导下规范软件架构设计，实现软件模块及子系统",
                "负责产品标定、诊断、测试、升级工具或设备的软件开发；",
                "配合硬件工程师和测试工程师，完成产品的各项试验；",
                "在指导下编写软件开发及测试相关文档；",
                "为客户和公司其他部门提供负责产品的技术支持；",
                "完成部门经理安排的其他工作。",
    
            ],
            jobQualification: [
                 "本科及以上学历，计算机、自动化、电子信息工程、电气及相关专业；",
                 "有基础的模拟、数字电子技术知识；",
                 "具有单片机应用开发经验；",
                 "具有一定的编程经验；",
                 "具有英文专业资料阅读能力。",
            ],
            qualificationRemark: "包括学历专业、工作经验、技能证书等，以下条件至少满足两条以上",

            socialBenefits: ["五险一金", "交通补贴", "餐饮补贴", "定期体检", "弹性工作"]

        },


        {   
            id:2,
            jobTitle: '硬件工程师 ( 储备 )',
            jobType: '研发技术',
            workplace: '上海',
            county: '浦东新区',
            jobCategory: '全职',
            releaseTime: '2023-07-22',
            jobDescription: [
                "基于硬件框图，以及需求，进行原理图设计；",
                "制定模块化电路设计规范；",
                "硬件设计计算；",
                "MCU端口资源分析；",
                "关键电路的仿真报告；",
                "协助硬件测试工程师进行模块化电路测试；",
                "DFMEA的编写；",
                "原理图设计审查表编写和维护。",
            ],
            jobQualification: [
                 "本科及本科以上；",
                 "熟练使用Protel,PADS软件；",
                 "可无工作经验，有工作经验者优先。",
            ],
            qualificationRemark: "",

            socialBenefits: ["五险一金", "交通补贴", "餐饮补贴", "定期体检", "弹性工作"]

        },



    ]
})



