<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-25 08:19:52
-->
<template>
  <div class="image-preview-container"  :style="{width:width?width+'px':'100%',height:height?height+'px':'100%'}" 
  v-loading="loading"
  element-loading-text="加载中"
  >
    <el-image
      :style="{width:'100%',height:'100%'}"
      :src="src"
      @load="handleLoad"
      :z-index="10000"
      :preview-src-list="previewurl"
      :fit="fit"
      lazy
      title="点击放大查看"
      >
    </el-image>
  </div>
</template>

<script>
export default {
   props: {
    src: {
        type: String,
        default: require("@/assets/img/shouyebg.png"),
    },

    fit: {
        type: String,
        default: 'cover'
    },
    width: {
        type: Number,
        default: 0,
    },

    height: {
        type: Number,
        default: 0,
    },
    previewurl: {
        type: Array,
        required:false
    }
   },
   data(){
    return {
        srcList: [
        require("@/assets/img/shouyebg.png"),
        require("@/assets/img/shouyebg1.jpg"),
      ],
      loading: true,//加载效果
    }
   },
   methods: {
      handleLoad() {
        this.loading = false;
        this.$emit('load');
      },
    },
}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

.placeholder{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2rem;
  color:@words;
}


</style>