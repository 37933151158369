<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-10 08:46:29
-->

<template>
  <div class="carousel-container">
    <template>
        <el-carousel  :height="height + 'px'" :interval="interval" :autoplay="imgLoaded">
            <el-carousel-item v-for="item,sub in carouselData" :key="sub" >
              <div class="carousel-box" v-show="isThisImgLoader(sub)">
 
                   <div class="carousel-title">
                    <p> {{ item.title }} </p>
                   </div>


                   <img :src="item.imgurl" alt="" class="carousel-img" @load="handleLoad(sub)">
     
                
              </div>

              <div class="placeholder-wrapper" v-show="!isThisImgLoader(sub)">
                <Placeholder 
                :windowWidth = "width"
                />
              </div>
            </el-carousel-item>
        </el-carousel>
    </template>
  </div>
</template>

<script>
import Placeholder from "@/components/Placeholder";
export default {
  props: {
    //轮播图的高度
    height: {
      type: Number,
      default: 300,
    },

    width: {
      type: Number,
      default: 1201,
    },
    //轮播图的间隔时间
    interval: {
       type: Number,
       default: 3000,//毫秒
    },
    //轮播图的图片
    carouselData: {
      
      type: Array,
      require: true,

    }
  },

  components:{
    Placeholder,
  },
  data(){
    return {
      url: require("@/assets/img/shouyebg.png"),
      imgLoaded: false,//图片是否加载完成
      imgLoaded0: false,      
      imgLoaded1: false,
      imgLoaded2: false,
    }
  },

  computed: {
    isThisImgLoader(){
      const _This = this;
      return function(sub){
        return  _This[`imgLoaded${sub}`];
        
      };
    }
  },

  methods: {
     handleLoad(sub){
      this.imgLoaded = true;
      this[`imgLoaded${sub}`] = true;

     },

  },

}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
.carousel-container{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom , #A9BEEC, #174094);

  .placeholder-wrapper{
    width: 100%;
    height: 100%;
  }
  .carousel-box{
    width: 100%;
    height: 100%;
    // display: flex;
    .carousel-title{
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 5rem;
      color:white;
      letter-spacing:1rem;
      p{
        width: 100% !important;
        margin:0 !important;
        padding: 0 !important;
        text-align: center;
      }
    }

    @media (max-width: 996px){
      .carousel-title{
        font-size: 4rem;
        letter-spacing: 0.8rem;
      }
    }

    @media (max-width: 768px){
      .carousel-title{
        font-size: 3rem;
        letter-spacing: 0.5rem;
      }
    }

    @media (max-width: 576px){
      .carousel-title{
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
      }
    }

    @media (max-width: 388px){
      .carousel-title{
        font-size: 1rem;
        // letter-spacing: 0.1rem;
      }

      
    }


    .carousel-img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .el-carousel__arrow{
      font-size: 3rem;
    }

  }

}
</style>