<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-22 08:55:47
-->
<template>
  <div class="submenu-container">

    <!-- 二级菜单分类导航 -->
    <div class="all-routerlink-wrapper" :class="{'hide': hideAllLink,'show':!hideAllLink}" :style="{height: all_link_height+'px'}" >
      <div class="all-link-wrapper" :style="{display:hideAllLink?'none':'block'}">
        <div class="all-link row">
        <div class="left-link-wrapper col" >
          <RouterLink
                    class="col-auto"
                    :exact="item.exact"
                    v-for="item in linkData"
                    :key="item.link"
                    :to="item.link"
                    active-class="selected" >
                    <span>{{ item.title }}</span>
          </RouterLink>
        </div>
        <div class="right-close-wrapper">
         <span @click="hideAllLink = !hideAllLink"><Icon type="icon-left" /></span> 
        </div>
      </div>
      </div>


    </div>

    <div class="image-wrapper">
         <div>
              <div :v-show="labelShow" class="label-wrapper row justify-content-start justify-content-md-center" :class="{'init-start':labelStart}"> 
              <span class="hidden-xs-only eng-label">{{ engLabel }}</span>
              <span class="cn-label">{{ cnLabel }}</span>
            </div>
            <ImageLoader :height="ImgHeight" @load="handleLoad" :src="img_url" :fit="fit" />
         </div>

         <div class="placeholder-wrapper" 
             v-show="labelStart" 
             :style="{height:submenuImgHei+'px'}">
                <Placeholder 
                :windowWidth = "winWidth"
                />
          </div>

     </div>
     <div class="nav-wrapper">
      <!-- 大尺寸展示 -->
        <div class="routerlink-wrapper hidden-xs-only">
             <div class="row justify-content-start ml-3">
                <RouterLink
                    class="col-auto"
                    :exact="item.exact"
                    v-for="item in linkData"
                    :key="item.link"
                    :to="item.link"
                    active-class="selected" >
                    <span>{{ item.title }}</span>
                </RouterLink>
            </div>
        </div>
        <!-- 小尺寸时展示 -->
        <div class="min-routerlink-wrapper hidden-sm-and-up">
           <span @click="hideAllLink = !hideAllLink">全部分类 ></span>
        </div>
     </div>



  </div>
</template>

<script>
import ImageLoader from "@/components/ImageLoader/";
import Icon from "@/components/Icon";
import Placeholder from "@/components/Placeholder";
export default {
   components: {
    ImageLoader,
    Placeholder,
    Icon,
   },
    props: {

        engLabel: {
            type: String,
            default: "EAST JOY LONG ELECTRONIC",
        },
        cnLabel: {
            type: String,
            default: '东方久乐汽车电子(上海)股份有限公司'
        },

        linkData: {
            type: Array,
            default: []
        },

        submenuImgHei: {
          type: Number,
          default: 388,
        },

        img_url: {
          type: String,
        },

        fit: {
          type: String
        }
    },

   data() {
     return {
      labelShow: false,
      labelStart:true,
      hideAllLink:true,
      winWidth: 1198,
      imgLoaded: false,//图片是否加载完成
      all_link_height: 600,
      windowSize: {},
     }
   },

   created() {
      this.getWindowSize();
   },

   computed:{
    ImgHeight(){
      return this.labelStart?0:this.submenuImgHei
    }
   },


   methods: {

    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.all_link_height = this.windowSize.Height;
         this.winWidth = this.windowSize.width;
      },
    handleLoad(){
      this.labelStart = false;//恢复原始位置
    },

    allRouterLink(){
      console.log('全部分类');
      this.hideAllLink = false;
    },

   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
  .submenu-container{
    position: relative;;
    width: 100%;;
    height: auto;


    .all-routerlink-wrapper{
      position: fixed;
      width: 100%;
      z-index: 20000;
      top:0;
      background-color: white;
      transition:all linear 0.6s;

      .all-link,
      .all-link-wrapper{
        width: 100%;
        height: 100%;
      }

      .left-link-wrapper{
        height: 100%;
        width: calc(100% - 2rem);

        a{
          display: block;
          width: 100%;
          padding: 1rem 3rem;
          color:@words;
          &:hover{
            background-color: @hoverLink !important;
            color:white;
          }
        }
        a.selected{
          background-color: @selectedLink;
          color: white;
        }
        
      }

      .right-close-wrapper{
        height: 100%;
        width: 4rem;
        background-color: #798BC6;
        padding-top: 7.2rem;
        span{
          display: inline-block;
          width: 100%;
          padding: 0.5rem 0;
          font-size: 1.2rem;
          text-align: center;
          background-color: #4B67C5;
          color:white;
          &:hover{
            background-color: #2C53B4;
          }
          &:active{
            background-color: #265FB7;
          }
        }
      }
    }

    .all-routerlink-wrapper.hide{
        width: 0;
        transform: scale(2);
      }

      .all-routerlink-wrapper.show{
        width: 100%;
        height: calc(100vh - 66px);
        transform: scale(1);
      }
  

    .image-wrapper{
      position: relative;
      width: 100%;
      height: auto;


      .placeholder-wrapper{
          width: 100%;
          height: 100%;
      }

      .label-wrapper{
        z-index: 800;
        .self-center;
        color: rgba(255, 255,255,1);
        text-align: center;
        transition: all 2s ease;
        opacity: 1;
        
        .eng-label{
          font-size: 3rem;
          font-weight: bold;
        }
        .cn-label{
          font-size: 2rem;
        }
      }

      .label-wrapper.init-start{
        top: 70%;
        color:rgba(255,255,255,0.6);
        opacity: 0;
      }

      @media (max-width: 768px){
        .image-loader-container{
          height: 188px !important;
        }
      }
    }

    .nav-wrapper{
        padding: 36px 0 8px 0;
        position: relative;
        width: 100%;
        height: auto;
        border-bottom: 1px solid @gray;

   
      
        .routerlink-wrapper{
         
          padding-left: 38px;

          & .selected span{
            background-color: @selectedLink;
            color: white;
        }

        & span{
            display: inline-block;
            padding: 10px 25px;
            &:hover{
                background-color: @hoverLink;
                color: white;
            }
        }
        }

        .min-routerlink-wrapper{
          background-color: #2C53B4;
          border-radius: 5px;
          &:hover{
            background-color: #265FB7;
          }
          span{
            width: 100%;
            display: inline-block;
            padding: 0.4rem 0;
            text-align: center;
            color:white;
            cursor: pointer;
          }
        }


       

    }

    @media (max-width: 768px){
          .nav-wrapper{
            border-bottom: none;
          }
        }

  }
</style>