/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-24 08:37:19
 */
import request from "./request";
//获取新闻数据
export async function getNewsList(){
   return await request.get( "/index.php/news-list");
}

//获取行业资讯
export async function getNewsIndList(){
   return await request.get( "/index.php/news-ind-list");
}


export async function getNewsAbstract(){
   return await request.get( "/index.php/news-abstract");
}



