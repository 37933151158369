/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-25 10:37:19
 */
import request from "./request";
//获取产品数据
export async function getProductDetails(){
   return await request.get( "/index.php/product-details");
}



