<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-19 08:28:24
-->
<template>
  <div class="company-container">

    <div class="submenu-wrapper">
      <Submenu 
      :engLabel="englabel"
      :cnLabel="cnLabel"
      :linkData="linkData"
    />
    </div>

    <div class="main-wrapper py-2 py-md-5 px-2">
       <!-- 公司简介 -->
       <div class="company-profile">
           <p class="split-header">公司介绍</p>
           <div class="row align-items-start justify-content-center">
             <div class="col-12 col-md-4 address-img row justify-content-start justify-content-md-center">
               <ImageLoader 
               :src="location"
               :height="268"
               />
             </div>
             <div class="col-12 col-md-8 introduct-wrapper mt-5 my-md-0 mx-2 mx-md-0">
               <p class="introduce-paragraph">东方久乐汽车电子（上海）股份有限公司成立于2012年4月，注册资金7000万。</p>
               <p class="introduce-paragraph">主营产:ACU/APM/DCU/PLGM/PLGS/DSCU/APL/EWP/AFS/ALS/EDM/PSD…</p>
               <p class="introduce-paragraph">掌握汽车电子核心技术
                  拥有完全的自主知识产权
                  拥有完善的开发流程
                  研发人员180余名。
                </p>
               <p class="introduce-paragraph">
                通过IATF16949质量体系认证
                通过CQM环境管理体系（ISO14001:2015）认证
               </p>
               <p class="introduce-paragraph">通过职业健康安全管理体系（ISO45001:2018）认证
                承担（参与）多项国家863项目及上海科研项目。</p>
             </div>
           </div> 
       </div>


        <!-- 发展历程 -->
        <div class="img-wrapper">
          <p class="split-header">发展历程</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="develop"
                :height="imgHeight"
                fit="fill"
                />
            </div>

          </div>
       </div>



       <!-- 公司布局 -->
        <div class="img-wrapper">
          <p class="split-header">东久电子整体布局</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="infrastructure"
                :height="img2Height"
                fit="fill"
                />
            </div>

          </div>
       </div>

       <!-- 股份占比 -->
       <div class="img-wrapper">
          <!-- <p class="split-header">东久电子股份图</p> -->
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="shareratio"
                :height="img3Height"
                fit="fill"
                />
            </div> 
         </div>
        </div>

      </div>

  </div>
</template>

<script>
import { AboutLink } from "@/assets/js/submenulink.js";
import Submenu from "@/components/Submenu/";
import ImageLoader from "@/components/ImageLoader/";
export default {
   components: {
    Submenu,
    ImageLoader,
   },
  
   data() {
    return {
      englabel: 'ABOUT US',
      cnLabel: '关于东久电子',

      imgHeight: 568,
      img_aspect_ratio: (1220 / 392).toFixed(2),

      img2Height: 568,
      img2_aspect_ratio: (1246 / 569).toFixed(2),

      img3Height: 568,
      img3_aspect_ratio: (1103 / 577).toFixed(2),

      windowSize: {},
      linkData: [],
      location: require("@/assets/img/About/gongsijianjie/location.png"),
      develop: require("@/assets/img/About/gongsijianjie/fazhanlicheng.png"),
      infrastructure: require("@/assets/img/About/gongsijianjie/infrastructure.png"),
      shareratio: require("@/assets/img/About/gongsijianjie/shareratio.png"),
    }
  },

  created() {
      this.getWindowSize();
      this.linkData = AboutLink;
   },


  mounted() {
      //拉伸窗口时触发
      window.onresize = () => {
          this.getWindowSize();
          
      }

  },
   methods: {
    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
         this.img2Height = Math.round(this.windowSize.width / this.img2_aspect_ratio); 
         this.img3Height = Math.round(this.windowSize.width / this.img3_aspect_ratio); 
      },
   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

  .company-container{
    position: relative;;
    width: 100%;;
    height: auto;

    .general-layout;


      .company-profile{
        padding: 0 10px;
        margin: 2rem 0;
        & .image-loader-container{
          width: 188px;
        }

      }


    
  }

</style>