<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-08 17:31:30
-->
<template>
  <div class="footer-container pt-md-3 pt-2 px-2">
    <div class="py-md-3">
      <div class="row align-items-start justify-content-center">
      <div class="col-6 col-md-3 pb-3" v-for="item,index in navData" :key="index">
           <h4 class="nav-link-title">{{ item.title }}</h4>
           <nav>
            <RouterLink :to="items.index" v-for="items,sub in item.subs" :key="sub" >{{ items.title }}</RouterLink>
           </nav>
        </div>

        <div class="col-6 col-md-3 pb-3">
          <h4 class="nav-link-title">联系我们</h4>
          
            <p>地址:上海市浦东新区合庆镇仁庆路309号</p>
            <p>电话: 021-58978200</p>
            <p>传真: 021-50491388</p>
            <p class="email-box">邮箱: <a href="mailto:jldz@ejlae.com">jldz@ejlae.com</a></p>
           
        </div>
    </div>
    </div>


      <div class="row mt-3 mb-2 justify-content-center">
       <div class="col-sm internet-nb py-3 px-2">
        <span>© 2012 东久电子</span><a href="https://beian.miit.gov.cn/#/Integrated/index" class="internet-content-provider">万维网备案号：沪ICP备13030507号</a>
        </div>
      </div>

      
    </div>

</template>

<script>
import Icon from "@/components/Icon/"
export default {
   components: {
    Icon,
   },

   data(){
      return {
        navData: [
            {

                title: '产品',
                subs: [
                  { index: '/Product/majorproduct', title: '主要产品'},
                  { index: '/Product/otherproduct', title: '其它产品'},
                  { index: '/Product/supplier', title: '主要供应商'},
                ]
              },

              {
                title: '人才招聘',
                subs: [
                  { index: '/Jobs/recinf', title: '招聘信息'},
                  { index: '/Jobs/trainf', title: '培训信息'},
                  { index: '/Jobs/talstr', title: '人才战略'}
                ]
              },

              {
                title:'关于我们',
                subs: [
                  { index: '/about/comprofile', title: '公司简介'},
                  { index: '/about/rdgroup', title: '研发团队'},
                  // { index: '/about/tarmarket', title: '市场与客户'},
                  // { index: '/about/orgstr', title: '组织架构'},
                  { index: '/about/enthonor', title: '企业荣誉'},
                  { index: '/about/testcenter', title: '测试中心'},
                  { index: '/about/profacility', title: '生产设施'},
                ]
            },
          
        ]
      }
   },


}
</script>

<style scoped lang="less">

   @import "~@/styles/global.less";
   @import "~@/styles/color.less";
   .footer-container{
     height: 100%;
     width: 100%;
     position: relative;;
    //  background-image: linear-gradient(to bottom , #7A88FF, #7AFFAF);
     background-image: linear-gradient(to bottom , #7D98D3, #174094);
     color:white !important;
     text-align: center;

     .nav-link-title{
       margin: 1rem 0;
     }

     nav{
      width: 100%;
      a{
        display: block;
      }

     }
  

     .internet-nb{
      // text-align: center;
      font-size: 0.8rem;
      background-color: #083279;

      a,span{
        color:@lightWords !important;
      }
      span{
        margin-right: 2rem;
      }
     }

     a{
        margin-top: 5px;
        color:white !important;
        &:hover{
          color:#6B9EEE !important;
        }
      }

    //  a{
    //   margin-top: 5px;
    //   color:white !important;
    //  }
    //  padding: 18px 20px;

   }

   @media (max-width: 575.9px){
    .footer-container{
      text-align: start;
    }

    h4{
      font-size: 1.2rem;
    }

    a{
      font-size: 0.9rem;
    }

   }
</style>