<template>
  <div class="placeholder-container">
    <div 
       class="placeholder-background-container"
       :class="{
        'sm-wrapper':windowWidth < 576,
        'md-wrapper': windowWidth >= 576 && windowWidth <1200, 
        'lg-wrapper': windowWidth >= 1200
        }"
    >
        <div class="space"></div>
        <div class="sun"></div>
        <div class="moon"></div>
        <div class="label-text-container">
            <div class="label-wrapper">
                <span>东方久乐汽车电子(上海)股份有限公司</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

    props: {
        windowWidth: {
            type: Number,
            default: 1201,
        }
    }

}
</script>

<style scoped>
@import "./animation.css";
  .placeholder-container{
    position: relative;
    width: 100%;
    height: 100%;
  }


</style>