/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 08:54:11
 */
export const AboutLink = [
    { link: '/about/comprofile', title: '公司简介'},
    { link: '/about/rdgroup', title: '研发团队'},
    { link: '/about/tarmarket', title: '市场与客户'},
    { link: '/about/orgstr', title: '组织架构'},
    { link: '/about/enthonor', title: '企业荣誉'},
    { link: '/about/testcenter', title: '测试中心'},
    { link: '/about/profacility', title: '生产设施'},
];


export const ProductLink = [
    { link: '/Product/majorproduct', title: '主要产品'},
    { link: '/Product/otherproduct', title: '其它产品'},
    { link: '/Product/supplier', title: '主要供应商'},
];

export const NewsArticleLink = [
    { link: '/News/cominf', title: '公司资讯'},
    { link: '/News/indinf', title: '行业资讯'},
    { link: '/News/medinf', title: '媒体资讯'}
];


export const JobsLink = [
    { link: '/Jobs/recinf', title: '招聘信息'},
    { link: '/Jobs/trainf', title: '培训信息'},
    { link: '/Jobs/talstr', title: '人才战略'}
];



