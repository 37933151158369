<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-17 11:16:32
-->
<template>
  <div class="app-container container-fluid">
    <Layout> 

     <template #default>
        <RouterView />
     </template>

   </Layout>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
   components: {
    Layout,
    Header,
    Footer
   },

   data(){
    return {
      scrollData: {},
      hideSideBar: true,
    }
  },

}

</script>

<style scoped lang="less">
</style>