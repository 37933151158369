<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-16 09:43:47
-->
<template>
    <i class="iconfont icon-container" :class="type"></i>
  </template>
  
  <script>
  
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    computed: {
    },
  };
  </script>
  
  <style scoped>
  @import "//at.alicdn.com/t/c/font_4067296_o88wttdmqa8.css";
  .iconfont {
    color: inherit;
    font-size: inherit;
  }
  </style>
  