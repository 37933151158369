import Mock from "mockjs"

Mock.mock("/index.php/about-honor","get",{
    code: 0,//错误码
    msg: "",//错误消息
    //data为具体的消息内容，如果code不为0，则必为null
    data: [
        {   
            id:1,
            text: `2022年吉利--优秀供应商`,
            img: [
                require("@/assets/img/About/qiyerongyu/jili.png"),
            ]
        },

        {   
            id:2,
            text: `2022年江淮--金点子奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jianghuai.png"),
                require("@/assets/img/About/qiyerongyu/jianghuai2.png"),
            ]
        },

        {   
            id:3,
            text: `2022年江铃--最佳协作奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jiangling.png"),
            ]
        },
        {   
            id:4,
            text: `2022年红旗--攻坚克难·旗志奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/hongqi.png"),
                require("@/assets/img/About/qiyerongyu/hongqi2.png"),
            ]
        },

        {   
            id:5,
            text: `2022年奔腾--共克时艰奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/benteng.png"),
            ]
        },

        {   
            id:6,
            text: `2022年奔腾--卓越贡献奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/benteng2.png"),
            ]
        },
        {   
            id:7,
            text: `2022年江西南昌--质量贡献奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jiangxinanchang.png"),
            ]
        },

        {   
            id:8,
            text: `2021年吉利--最佳同路人奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jili2.png"),
            ]
        },

        {   
            id:9,
            text: `2021年度吉利--优秀供应商`,
            img: [
                require("@/assets/img/About/qiyerongyu/jili3.png"),
            ]
        },
        {   
            id:10,
            text: `2021年红旗--攻坚克难·旗志奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/hongqi3.png"),
                require("@/assets/img/About/qiyerongyu/hongqi4.png"),
            ]
        },

        {   
            id:11,
            text: `2021年奔腾--共克时艰奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/benteng3.png"),
            ]
        },

        {   
            id:12,
            text: `2021年重庆长安汽车--协同贡献奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/changan.png"),
            ]
        },
        {   
            id:13,
            text: `2021年度东风柳汽--交付贡献奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/liuqi.png"),
            ]
        },

        {   
            id:14,
            text: `2019年江淮--优秀供应商奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jianghuai3.png"),
                require("@/assets/img/About/qiyerongyu/jianghuai4.png"),

            ]
        },

        {   
            id:15,
            text: `2019年江淮--量产贡献奖`,
            img: [
                require("@/assets/img/About/qiyerongyu/jianghuai5.png"),
            ]
        },
       
    ]
})