/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-29 15:36:36
 */
import request from "./request";
//获取产品数据
export async function getHonorIntroduct(){
   return await request.get( "/index.php/about-honor");
}
