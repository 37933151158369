<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-26 09:26:18
-->
<template>
  <div class="empty-container">
    <div class="icon-wrapper">    
      <Icon type="icon-img_kongxiangzi" />
     <p>{{ text }}</p>
    </div>

    <p class="link"><a href="/">返回首页</a></p>
    <p class="link"><a href="javascript:void(0);" @click="goBack">返回上级菜单</a></p>

  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: "这里空空如也",
    },
  },

  methods: {
      goBack(){
          this.$router.go(-1);
      }
  }
};
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
.empty-container {
  .self-center();
  color: @gray;
  text-align: center;

  
}


.link a{
    &:hover{
      color:@hoverLink;
    }
    &:active{
      color:@selectedLink;
    }
  }

.icon-wrapper{
  .icon-container {
     font-size: 10rem;
  }

  p{
    font-size: 3rem;
  }

  
@media (max-width:576px){
.icon-container {
  font-size: 3rem;
}
  p{
    font-size: 1.5rem;
  }
}

}



</style>
