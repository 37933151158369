/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-08 13:11:50
 */
import Home from "@/views/HomePage/index.vue";//主页

import ComProfile from "@/views/About/CompanyProfile.vue";//公司简介
import RdGroup from "@/views/About/RdGroup.vue";//研发团队
import TarMarket from "@/views/About/TargetingMarket";//市场与客户
import OrgStr from "@/views/About/OrganizationalStructure";//组织架构
import EntHonor from "@/views/About/EnterpriseHonor";//企业荣誉
import TestCenter from "@/views/About/TestCenter.vue";//研发团队
import ProFacility from "@/views/About/ProductionFacility";//生产设施

import Product from "@/views/Product/MajorProduct.vue";//主要产品
import OtherProduct from "@/views/Product/OtherProducts.vue";//其它产品
import Supplier from "@/views/Product/Supplier.vue";//主要供应商

import ComInf from "@/views/News/CompanyInformation";//公司资讯
import IndInf from "@/views/News/IndustryInformation";//行业资讯
import MedInf from "@/views/News/MediaInformation";//媒体资讯

import RecInf from "@/views/Jobs/RecruitmentInformation";//招聘信息
import TraInf from "@/views/Jobs/TrainingInformation";//培训信息
import TalStr from "@/views/Jobs/TalentStrategy";//人才战略


import ProSup from "@/views/Support/ProductSupport";//产品支持
import TecSup from "@/views/Support/TechnicalSupport";//技术支持
import DataDownload from "@/views/Support/DataDownload";//资料下载
import AftService from "@/views/Support/AfterService";//售后信息


export default [
  // 关于我们
  { name: "index", path: "/", component: Home },
  { name: "home", path: "/home", component: Home },
  { name: "comprofile", path: "/about/comprofile", component: ComProfile },
  { name: "rdgroup", path: "/about/rdgroup", component: RdGroup },
  { name: "tarmarket", path: "/about/tarmarket", component: TarMarket },
  { name: "orgstr", path: "/about/orgstr", component: OrgStr },
  { name: "enthonor", path: "/about/enthonor", component: EntHonor },
  { name: "testcenter", path: "/about/testcenter", component: TestCenter },
  { name: "profacility", path: "/about/profacility", component: ProFacility },


  // 产品
  { name: "product", path: "/product/majorproduct", component: Product },
  { name: "otherproduct", path: "/product/otherproduct", component: OtherProduct },
  { name: "supplier", path: "/product/supplier", component: Supplier },


  //资讯
  { name: "cominf", path: "/News/cominf", component: ComInf },
  { name: "indinf", path: "/News/indinf", component: IndInf },
  { name: "medinf", path: "/News/medinf", component: MedInf },

  { name: "recinf", path: "/Jobs/recinf", component: RecInf },
  { name: "trainf", path: "/Jobs/trainf", component: TraInf },
  { name: "talstr", path: "/Jobs/talstr", component: TalStr },

  { name: "prosup", path: "/Support/prosup", component: ProSup },
  { name: "tecsup", path: "/Support/tecsup", component: TecSup },
  { name: "datadownload", path: "/Support/datadownload", component: DataDownload },
  { name: "aftservice", path: "/Support/aftservice", component: AftService },

];
