<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 08:20:39
-->
<template>

    <div class="row justify-content-center" :style="{height: errorPageHeight+'px'}">
      <div class="col-12 col-md-8">
        <Empty/>
      </div>

    </div>
  </template>
  
  <script>


  import Empty from "@/components/Empty";
  export default {
     components: {
      Empty,
     },
    
     data() {
      return {
        windowSize: {},
        footerHeight:0,
      }
    },
  
    created() {
        this.getWindowSize();
       
     },
  
    computed: {
       errorPageHeight(){
          return this.windowSize.Height - this.footerHeight - 66;
       }
     },
  
    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           let footerDom = document.querySelectorAll(".footer-container")[0];
           this.footerHeight = footerDom.clientHeight;
        },
     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
  @import "~@/styles/mixin.less";
  .error-page{
    text-align: center;
    // height: 368px;
  }  
  
  
  </style>