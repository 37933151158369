<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-06 16:22:03
-->
<template>
  <div class="navbar-container">
    <el-menu
      :default-active="onRoutes"
        class="el-menu-demo"
        :mode="mode"
        router
        active-text-color="#ffd04b">
      
        <template v-for="item in navData">
            <template v-if="item.subs">
              <el-submenu :index="item.index" :key="item.index" >
                 <template slot="title">{{ item.title }}</template>
                 <el-menu-item v-for="subItem in item.subs" :key="subItem.index" :index="subItem.index" @click="clickNavLink">
                  {{ subItem.title }}
                 </el-menu-item>
             </el-submenu>
            </template>
            <template v-else>
             <el-menu-item :index="item.index" :key="item.index" @click="clickNavLink">{{ item.title }}</el-menu-item>
            </template>
    
        </template>
</el-menu>

  </div>
</template>

<script>

export default {
  props:{
    mode: {
      type: String,
      default: "horizontal",
    }
  },
  data() {
    return {
      activeIndex: 1,
      navData: [
        {
          index: '/home',
          title: '首页'
        },
        {
          index: 'About',
          title: '关于我们',
          subs: [
            { index: '/about/comprofile', title: '公司简介'},
            { index: '/about/rdgroup', title: '研发团队'},
            { index: '/about/tarmarket', title: '市场与客户'},
            { index: '/about/orgstr', title: '组织架构'},
            { index: '/about/enthonor', title: '企业荣誉'},
            { index: '/about/testcenter', title: '测试中心'},
            { index: '/about/profacility', title: '生产设施'},
          ]
        },

        {
          index: 'Product',
          title: '产品',
          subs: [
            { index: '/Product/majorproduct', title: '主要产品'},
            { index: '/Product/otherproduct', title: '其它产品'},
            { index: '/Product/supplier', title: '主要供应商'},
          ]
        },

        {
          index: 'News',
          title: '最新资讯',
          subs: [
            { index: '/News/cominf', title: '公司资讯'},
            { index: '/News/indinf', title: '行业资讯'},
            { index: '/News/medinf', title: '媒体资讯'}
          ]
        },

        {
          index: 'Jobs',
          title: '人才招聘',
          subs: [
            { index: '/Jobs/recinf', title: '招聘信息'},
            { index: '/Jobs/trainf', title: '培训信息'},
            { index: '/Jobs/talstr', title: '人才战略'}
          ]
        },

        // {
        //   index: 'Support',
        //   title: '技术与服务',
        //   subs: [
        //     { index: '/Support/prosup', title: '产品支持'},
        //     { index: '/Support/tecsup', title: '技术支持'},
        //     { index: '/Support/datadownload', title: '资料下载'},
        //     { index: '/Support/aftservice', title: '售后信息'}
        //   ]
        // },






      ]
    }
  },
  computed: {
        onRoutes() {
            let curPathname = location.pathname.toLowerCase(); // 当前浏览器的访问路径
            return this.$route.path.replace(curPathname, '');
        }
    },

    methods: {
      clickNavLink(){
        this.$emit('clickNavbar','');
      }
    }
}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
  .navbar-container{
    // display: flex;
    margin:0;
    background-color: initial;
    // justify-content: flex-end;
    // text-align: center;
    padding-right: 15px;
    .el-menu{
      height: @header-height;
      line-height: @header-height;
      padding: 3px 0;
      border:none;
      background-color: initial;
 
    }

    // .navtar-container{
       
    // }
  }


</style>