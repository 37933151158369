/*
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-17 11:16:32
 */
import Vue from 'vue'
import App from './App.vue'
import router from "./router/";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'jquery/dist/jquery.min'
import "./styles/global.less";
import 'bootstrap/dist/css/bootstrap.css'

import "./mock";

Vue.use(ElementUI);

Vue.config.productionTip = false

//解决路由跳转后滚动条不自动回到最顶部
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
