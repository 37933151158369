<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-20 08:14:07
-->
<template>
    <div class="image-loader-container" :style="{'height':height+'px'}"
      v-loading="loading"
      element-loading-text="加载中"
    >

      <el-image
      @load="handleLoad"
      :style="{width:'100%',height:'100%'}"
      :src="src"
      :z-index="10000"
      :fit="fit"
      lazy
      >
    
    </el-image>


    </div>
  </template>
  
  <script>
  export default {
    props: {
      src: {
        type: String,
        default: require("@/assets/img/shouyebg2.jpg"),
      },

      height: {
        type: Number,
        default: 388,
      },
      fit: {
        type: String,
        default: 'cover'
      },
    },
    data() {
      return {
        originLoaded: false, //  原图是否加载完成
        loading: true,//加载效果
      };
    },

    methods: {
      handleLoad() {
        this.originLoaded = true;
        this.loading = false;
        this.$emit('load');
        // setTimeout(() => {
         
        //   this.$emit("load");
        // }, 5000);
        



      },
    },
  };
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/mixin.less";
  .image-loader-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      .self-fill();
      // object-fit: cover;
    }
  
    .placeholder {
      filter: blur(2vw);
    }
  }
  </style>
  