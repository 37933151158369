<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-17 11:30:59
-->
<template>
  <div class="layout-container container-fluid">
      <div class="header">
        <Header 
        @clickIconBtn ="showSidebar" 
        :iconType = "hideSideBar"
        />
        <!-- <slot name="header"></slot> -->
      </div>

      <div class="main">
        <!-- 左侧主导航 -->
         <div class="sidebar-wrapper hidden-sm-and-up" :class="{'hide': hideSideBar,'show':!hideSideBar}" v-if="leftSidebar">
             <div :style="{display:hideSideBar?'none':'block'}">
              <Navbar mode="vertical"  @clickNavbar="hideSide" />
             </div>
         </div>


         <div class="main-wrapper">
            <slot name="default"></slot>
         </div>
      </div>

      <div class="footer">
        <Footer />
        <!-- <slot name="footer"></slot> -->
      </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {

   data(){
    return {
      hideSideBar: true,
      leftSidebar: true,
    }
   },
   components: {
    Header,
    Navbar,
    Footer,
   },

   methods: {
    hideSide(){
      this.leftSidebar = false;
      this.hideSideBar = true;
    },

    showSidebar() {
      this.leftSidebar = true;
      this.hideSideBar = !this.hideSideBar;
    }
   }
}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
  .layout-container{
    width: 100%;
    height: auto;
    // overflow-x: hidden;
    .header{
      width: 100%;
      height: @header-height;
      position: fixed;
      z-index: 10000;
      background-color: #F0F3F4;

    }

    .main{
      position: relative;
      width: 100%;
      top: @header-height;
      height: auto;

      .sidebar-wrapper{
        position: fixed;
        width: 388px;
        z-index: 1000;
        background-color: white;
        height: calc(100vh - 66px);
        transition:all linear 0.6s;
      }

      .sidebar-wrapper.hide{
        height: 0;
        // width: 0;
        transform: scale(2);
      }

      .sidebar-wrapper.show{
        width: 388px;
        height: calc(100vh - 66px);
        transform: scale(1);
      }
    }

    .footer{
      position: relative;
      top: @header-height;
      // width: 100%;
      height: auto;
      overflow-x:hidden ;
    }

    @media (max-width: 575.9px) {
      .sidebar-wrapper{
        width: 100% !important;
      }
    }
    
  }
</style>