<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 08:42:52
-->
<template>
    <div class="test-center-container">
  
      <div class="submenu-wrapper">
        <Submenu 
        :engLabel="englabel"
        :cnLabel="cnLabel"
        :linkData="linkData"
      />
      </div>
  
      <div class="main-wrapper py-2 py-md-5 px-2">
          <!-- 测试中心 -->
          <div class="img-wrapper">
          <p class="split-header">测试中心</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="img"
                :height="imgHeight"
                fit="fill"
                />
            </div> 
         </div>
        </div>

        <!-- 实验能力 -->
        <div class="img-wrapper">
            <p class="split-header">实验能力</p>
            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
                <ImageLoader 
                    :src="img1"
                    :height="img2Height"
                    fit="fill"
                    />
                </div> 
            </div>
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import { AboutLink } from "@/assets/js/submenulink.js";
  import Submenu from "@/components/Submenu/";
  import ImageLoader from "@/components/ImageLoader/";
  export default {
     components: {
      Submenu,
      ImageLoader,
     },
    
     data() {
      return {
        englabel: 'ABOUT US',
        cnLabel: '关于东久电子',
        imgHeight: 568,
        img_aspect_ratio: (1220 / 392).toFixed(2),

        img2Height: 568,
        img2_aspect_ratio: (1246 / 569).toFixed(2),

        windowSize: {},
        linkData: [],
        img: require("@/assets/img/About/ceshizhongxin/ceshizhongxin.png"),
        img1: require("@/assets/img/About/ceshizhongxin/shiyannengli.png"),

      }
    },
  
    created() {
        this.getWindowSize();
        this.linkData = AboutLink;
     },


    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
           this.img2Height = Math.round(this.windowSize.width / this.img2_aspect_ratio); 
        },
     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
    .test-center-container{
      position: relative;;
      width: 100%;;
      height: auto;

      .general-layout;
  
    }
  
  </style>