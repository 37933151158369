<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-23 10:31:30
-->
<template>
    <div class="other-product-container">
  
      <div class="submenu-wrapper">
        <Submenu 
        :engLabel="englabel"
        :cnLabel="cnLabel"
        :linkData="linkData"
      />
      </div>
  
      <div class="main-wrapper py-2 py-md-5 px-2">
          <!-- 其它产品 -->
          <div class="img-wrapper">
          <p class="split-header">其它产品</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10">
              <ImageLoader 
                :src="img"
                :height="imgHeight"
                fit="fill"
                />
            </div> 
         </div>
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import { ProductLink } from "@/assets/js/submenulink.js";
  import Submenu from "@/components/Submenu/";
  import ImageLoader from "@/components/ImageLoader/";
  export default {
     components: {
      Submenu,
      ImageLoader,
     },
    
     data() {
      return {
        englabel: 'PRODUCT',
        cnLabel: '产品',
        imgHeight: 568,
        img_aspect_ratio: (1371 / 413).toFixed(2),
        windowSize: {},
        linkData: [],
        img: require("@/assets/img/Product/otherProduct.png"),

      }
    },
  
    created() {
        this.getWindowSize();
        this.linkData = ProductLink;
     },
  

    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           this.imgHeight = Math.round(this.windowSize.width / this.img_aspect_ratio); 
        },
     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
    .other-product-container{
      position: relative;;
      width: 100%;;
      height: auto;
      .general-layout;
      
    }
  
  </style>