<template>
  <div class="tarmarket-container">

    <div class="submenu-wrapper">
      <Submenu 
      :engLabel="englabel"
      :cnLabel="cnLabel"
      :linkData="linkData"
    />
    </div>

    <div class="main-wrapper py-2 py-md-5 px-2">

    <!-- 合作客户 -->
  <div class="img-wrapper">
        <p class="split-header">合作客户</p>
        <div class="row justify-content-center">
          <div class="col-12 col-xl-10">
            <ImageLoader 
              :src="client"
              :height="imgHeight"
              fit="fill"
              />
          </div> 
        </div>
      </div>

       <!-- 市场情况 -->
        <div class="img-wrapper">
          <p class="split-header">市场情况</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-8">
              <ImageLoader 
                :src="bazaar"
                :height="img2Height"
                fit="fill"
                />
            </div> 
         </div>
        </div>

        <!-- 主机厂配套 -->
        <div class="img-wrapper">
          <p class="split-header">主机厂配套</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10 ">
              <ImageLoader 
                :src="salesStatus"
                :height="463"
                fit="fill"
                />
            </div> 
         </div>
        </div>

      </div>

  </div>
</template>

<script>
import { AboutLink } from "@/assets/js/submenulink.js";
import Submenu from "@/components/Submenu/";
import ImageLoader from "@/components/ImageLoader/";
export default {
   components: {
    Submenu,
    ImageLoader,
   },
  
   data() {
    return {
      englabel: 'ABOUT US',
      cnLabel: '关于东久电子',

      imgHeight: 568,
      img_aspect_ratio: (1127 / 531).toFixed(2),

      img2Height: 568,
      img2_aspect_ratio: (1058 / 590).toFixed(2),

      img3Height: 463,
      img3_aspect_ratio: (463 / 281).toFixed(2),

      windowSize: {},
      linkData: [],
      client: require("@/assets/img/About/shichangyukehu/hezuokehu.png"),
      bazaar: require("@/assets/img/About/shichangyukehu/shichangqingkuang.png"),
      salesStatus: require("@/assets/img/About/shichangyukehu/zhujichangpeitao.png"),
    }
  },

  created() {
      this.getWindowSize();
      this.linkData = AboutLink;
   },



  mounted() {
      //拉伸窗口时触发
      window.onresize = () => {
          this.getWindowSize();
          
      }

  },
   methods: {
    getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.imgHeight =  Math.round(this.windowSize.width / this.img_aspect_ratio); 
         this.img2Height = Math.round(this.windowSize.width / this.img2_aspect_ratio); 
         this.img3Height = Math.round(this.windowSize.width / this.img3_aspect_ratio); 
        },
   }


}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

  .tarmarket-container{
    position: relative;;
    width: 100%;;
    height: auto;
    .general-layout;

  }

</style>