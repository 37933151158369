<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-08 13:51:47
-->
<template>
   <div class="homepage-container">
         <div class="carousel-wrapper" :style="{height:unitHeights + 'px'}">
            <Carousel 
            :width="newWidth"
            :height="carouselHeight" 
            :carouselData="carouselData"  
            :interval="3000" 
            :autoplay="false" />
         </div>


      <div class="about-wrapper">
          <div class="about-container container-fluid my-6">
              <div>
                <p>
                  <span class="fontsize-max">关于我们</span>
                </p>
              </div>
          </div>

          <div class="row align-items-start justify-content-center about-label-wrapper hidden-xs-only">
            <div class="col-sm-3 border-right about-label">
               <div>
                  <Icon type="icon-qiye"/>
                  <span class="fontsize-max">2012</span>
                  <span class="label-unit">年</span>
               </div>

                <p>公司创立</p>
            </div>
            <div class="col-sm-3 border-right about-label">
               <div>
                  <Icon type="icon-fenzhijigou"/>
                  <span class="fontsize-max">5</span>
                  <span class="label-unit">处</span>
               </div>

               <p>分支机构</p>

            </div>
            <div class="col-sm-3 about-label">
               <div>
                  <Icon type="icon-rongyu"/>
                  <span class="fontsize-max">30+</span>
                  <span class="label-unit">个</span>
               </div>

               <p>企业荣誉</p>
            </div>
        
      </div>

      <div class="about-us-wrapper">
         <div class="row justify-content-start justify-content-md-center">
            <p class="col-12 col-md-9 px-2">
               东方久乐汽车电子（上海）股份有限公司成立于2012年4月，注册资金7000万。
               掌握汽车电子核心技术,拥有完全的自主知识产权,
               拥有完善的开发流程,研发人员180余名。
               主营产品: ACU/APM/DCU/PLGM/PLGS/DSCU/APL/EWP/AFS/ALS/EDM/PSD…
               通过IATF16949质量体系认证
               通过CQM环境管理体系（ISO14001:2015）认证
               通过职业健康安全管理体系（ISO45001:2018）认证
               承担（参与）多项国家863项目及上海科研项目。

               企业使命：技术创新、精益管理、专注汽车电子、提升客户价值
               企业愿景：打造中国自主的专业汽车电子科技企业
            </p>
         </div>

         <div class="row justify-content-start justify-content-md-center mt-3">
             <div class="col-12 col-md-3 card-wrapper" v-for="item in cards" :key="item.aLink">
                <Card 
                :cardHeight="cardHeight"
                :aLink="item.aLink"
                :label="item.label"
                :imgUrl="item.imgUrl"
                />
             </div>
         </div>
      </div>
   </div>


<!-- 主要产品 -->
   <div class="about-wrapper product-wrapper">
          <div class="about-container product-container container-fluid my-6">
              <div>
                <p>
                  <span class="fontsize-max">主要产品</span>
                </p>
              </div>
          </div>
        
      <div class="about-us-wrapper product-list">
         <div class="row justify-content-start justify-content-md-center">
            <p class="col-12 col-md-9 px-2">
               掌握汽车电子核心技术,拥有完全的自主知识产权,
               自研ACU/APM/DCU等多种产品
            </p>
       
         </div>

         <div class="row justify-content-start justify-content-md-center mt-3">
             <div class="col-12 col-md-3 card-wrapper2" v-for="item in cards2" :key="item.aLink">
                <Card 
                :cardHeight="cardHeight"
                :aLink="item.aLink"
                :label="item.label"
                :imgUrl="item.imgUrl"
                />
             </div>
         </div>
      </div>
   </div>

</div>
</template>

<script>
import Carousel from "@/components/Carousel";
import Icon from "@/components/Icon";
import Card from "@/components/Card";
export default {

   components: {
      Carousel,
      Icon,
      Card,
   },
   data() {
      return {
         windowSize: {},
         unitHeight: 1024,
         newHeight: 1024,
         newWidth: 1201,
         topHeight: 66,//顶部固定栏高度
         carouselPCI: 1,//首页轮播图的百分比
         cardHeight: 226,//card组件的初始高度

         carouselData: [
            {title:'东方久乐汽车电子(上海)股份有限公司',imgurl: require("@/assets/img/shouyebg.png")},
            {title:'专注汽车电子,提升客户价值',imgurl: require("@/assets/img/shouyebg1.jpg")},
            {title:'技术创新、精益管理',imgurl: require("@/assets/img/shouyebg2.jpg")},
         ],
      
         cards: [
               {aLink: '/about/comprofile',label: '公司简介',imgUrl: require("@/assets/img/gongsijianjie.png")},
               {aLink: '/about/enthonor',label: '企业荣誉',imgUrl: require("@/assets/img/gongsirongyu.png")},
               {aLink: '/about/rdgroup',label: '研发团队',imgUrl: require("@/assets/img/yanfatuandui.png")},
            ],

         cards2: [
               {aLink: '/Product/majorproduct',label: '主营产品',imgUrl: require("@/assets/img/zhuyaochanping.png")},
               {aLink: '/Product/otherproduct',label: '其它产品',imgUrl: require("@/assets/img/qitachanping.png")},
               {aLink: '/about/profacility',label: '生产设施',imgUrl: require("@/assets/img/About/shengchansheshi/shengchanliucheng.png")},
            ]
      
      
      }

     
      },
   created() {
      this.getWindowSize();
   },
   computed: {
     unitHeights() {
      return this.newHeight - this.topHeight;
     },

     carouselHeight() {
      return this.unitHeights * this.carouselPCI; 
   },
  },

  mounted() {
   //拉伸窗口时触发
   window.onresize = () => {
      this.getWindowSize();
   }

  },
   methods: {
      getWindowSize() {
         this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
         this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
         this.unitHeight = this.windowSize.Height;
         this.newWidth =  this.windowSize.width;

         if(this.windowSize.width < 576){
            this.newHeight = 266; 
            this.cardHeight = 288;
         }
         else{
            this.cardHeight = 226;
            this.newHeight = this.unitHeight > 880?this.unitHeight*0.7:this.unitHeight;
         } 
      },
   }
}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

.homepage-container{
   position: relative;;
   width: 100%;;
   height: auto;
   .carousel-wrapper{
      width: 100%;
   }

   .about-wrapper{
      margin-top: 4rem;
      width: 100%;
      height: auto;
      // padding: 25px 0;
      font-weight: bold;
      .about-container{
         display: flex;
         justify-content: center;
         align-items: center;
         color:@primary;
      }

      .about-label-wrapper{
          text-align: center;
          margin: 15px 0;
         .fontsize-max{
            color:@primary;
         }

         .iconfont{
            font-size: 3.8rem;
            margin-right: 10px;
         }

         .label-unit{
            margin-left: 8px;
            font-size: 1.2rem;
         }

         p{
            color:@lightWords; 
         }
      }

      .about-us-wrapper p{
         color:@words; 
         font-size: 1.2rem;
         letter-spacing: 0.2rem;//字间距
         line-height: 2.0rem;//行间距
         word-wrap: break-word;//字母换行
      }

      @media (max-width: 768px){
         .about-us-wrapper p{
            font-size: 1rem;
            letter-spacing: 0.2rem;//字间距
            line-height: 1.3rem;//行间距
         }
      }

      .card-wrapper{
         margin-top: 3rem;
         height: auto;
         margin-right: 26px;
      }
   }

   .product-wrapper{
      margin-top: 68px;
      padding-top: 36px;
      border-top: 1px solid #E1E1E1;
      .card-wrapper2{
         margin-top: 3rem;
         height: auto;
         margin-right: 26px;
      }
   }

   .product-list{
       
      margin-bottom: 5rem;
      p{
         text-align: center;
      }
   }

}

</style>