<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-19 08:28:24
-->
<template>
  <div class="card-container" >
        <RouterLink  :to="aLink" >
            <img :src="imgUrl" :alt="label" :style="{'height':cardHeight+'px'}">
            <span>{{label}}</span>
        </RouterLink>
  </div>
</template>

<script>
export default {
   props: {

      //展示数据
      aLink: {
        type: String,
        default: "#",
      },

      label: {
        type: String,
        default: '链接'
      },

      imgUrl: {
        type: String,
        default: ""
      },

      cardHeight: {
        type:Number,
        default: 226,
      },
   },

   computed: {
     cardHeights(){
       return this.cardHeight; 
     }
   },
   data(){
    return {
      datas: [
        {aLink: 'comprofile',label: '公司简介',imgUrl: require("@/assets/img/gongsijianjie.png")},
        {aLink: 'comprofile',label: '公司荣誉',imgUrl: require("@/assets/img/gongsirongyu.png")},
        {aLink: 'comprofile',label: '主营产品',imgUrl: require("@/assets/img/zhuyaochanping.png")},
      ],
    }
   },

}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";
    .card-container{
        width: 100%;
        position: relative;
        height: auto;
        transition: 0.3s;
        box-shadow: 0 0 2px 2px #E1E1E1;

        &:hover{
          margin-top: -8px;
          background-color: @primary;
          span{
            color:white !important;
          }
          
        }

        img{
          width: 100%;
          // object-fit: cover;
        }

        span{
          display: inline-block;
          width: 100%;
          height: 52px;
          line-height: 52px;
          text-align: center;
          font-size: 1.5rem;
          color:@lightWords; 
          // border-top: 1px solid @lightWords;
        }
   
    }
</style>