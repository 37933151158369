<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-07-24 10:44:16
-->
<template>
  <div class="recruitment-information-container">

    <div class="submenu-wrapper">
        <Submenu 
        engLabel=""
        cnLabel=""
        :linkData="linkData"
        :img_url="img"
        fit="fill"
      />
      </div>
  
      <div class="main-wrapper ">

        
         <div class="job-vacancy-wrapper" v-if="jobsListHide">
          <div class="tools-wrapper">
           <div class="row space-between">
              <div class="col-12 col-xl-8">
                <div class="row">
                  <div class="col-12 col-xl-auto">
                    <label for="job-address" class="tools-label">工作地点:</label>
                    <el-select v-model="workplace" multiple placeholder="工作地点" id="job-address" @change="changeJobType">
                      <el-option
                        v-for="item,sub in workplace_list"
                        :key="sub"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-12 col-xl-auto jobs-type-wrapper ml-xl-3">
                    <label for="job-type" class="tools-label">职位类别:</label>
                    <el-select v-model="job_type" multiple placeholder="职位类别" id="job-type" @change="changeJobType">
                      <el-option
                        v-for="item,sub in job_type_list"
                        :key="sub"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </div>
                </div>

              </div>
     

              <div class="col-12 col-xl-4 job-search-wrapper">
                <p class="tools-label"><span style="color:#40A1FF;margin-right: 0.3rem;">{{ jobCount }}</span>个在招职位:</p>
                <el-autocomplete
                  v-model="job_title"
                  :fetch-suggestions="queryJobTitle"
                  placeholder="请搜索职位名称"
                  suffix-icon="el-icon-search"
                  @select="changeJobType"
                  @change="changeJobType"
                  clearable
                ></el-autocomplete>
              </div>
           </div>
         </div>


         <!-- 招聘列表 -->
         <div class="job-vacancy-table">
          <el-table
              :data="checkData"
              :default-sort = "{prop: 'releaseTime', order: 'descending'}"
              :header-cell-style="{ 
              background:'#F0F3F4',color:'#000000',border: '1px solid #DBDCDD',height:'2rem'}"
              style="width: 100%"
              row-class-name="table-body-tr"
              @row-click="clickRow"
              >
              <el-table-column
                prop="jobTitle"
                label="职位名称"
               >
              </el-table-column>
              <el-table-column
                prop="jobType"
                label="职位类别"
                >
              </el-table-column>
              <el-table-column
                prop="workplace"
                label="工作地点"
                width="188"
                >
              </el-table-column>

              <el-table-column
                prop="jobCategory"
                label="工作类型"
                width="188"
                >
              </el-table-column>

              <el-table-column
                prop="releaseTime"
                label="发布时间"
                width="188"
                sortable
                >
              </el-table-column>

              <el-table-column prop="state" label="操作" align="center">
                    <template slot-scope="scope">
                        <a href="javascript:void(0);" @click="getJobsBody(scope.row.id)"><el-button type="primary" plain size="mini">查看详情</el-button></a>
                        <a href="mailto:yext@ejlae.com?cc=yanlh@ejlae.com"><el-button type="success" plain size="mini">投递简历</el-button></a>
                    </template>
                </el-table-column>
          </el-table>


         </div>

         </div>







        <!-- 资讯主体 -->
        <div class="job-vacancy-main py-1 py-md-3" v-if="!jobsListHide">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-xl-8 ">


              <div class="jobs-detail-header">
                 <div class="row">
                    <div class="col-12 col-xl-8">
                       <div class="job-title">
                        <h3 style="font-weight:700">{{ jobsBodyData.jobTitle }}</h3>
                       </div>

                       <div class="job-qualification">
                         <ul>
                            <li>{{ jobsBodyData.workplace + '-' + jobsBodyData.county }}</li>
                            <li>1-3年经验</li>
                            <li>本科</li>
                            <li>{{ jobsBodyData.releaseTime }}发布</li>
                         </ul>
                       </div>  

                       <div class="social-benefits">
                       
                        <el-tag type="success" size="mini" v-for="item, index in jobsBodyData.socialBenefits" :key="index">{{ item }}</el-tag>
                       </div>
                    </div>

                    <div class="col-12 col-xl-4 my-3 my-xl-0 align-self-end">
                       <div class="job-application ">
                        <el-button type="warning" icon="el-icon-refresh-left" @click="jobsListHide = !jobsListHide">返回列表</el-button>
                        <a href="mailto:yext@ejlae.com?cc=yanlh@ejlae.com"><el-button type="primary" icon="el-icon-s-promotion">申请职位</el-button></a>
                       </div>
                    </div>
                 </div>

              </div>





              <div class="jobs-detail-main">
                <div class="detail-module-wrapper">
                  <div class="module-labei">
                    <span class="separator-span"></span>
                    <span class="label-title-span">岗位职责: </span>
                  </div>
                  <div>
                    <p v-for="item, index in jobsBodyData.jobDescription" :key="index">{{ index + 1 + '、 ' + item }}</p>
                  </div>
                </div>

                <div class="detail-module-wrapper">
                  <div class="module-labei">
                    <span class="separator-span"></span>
                    <span class="label-title-span">职位要求: </span> <span v-if="jobsBodyData.qualificationRemark">( {{ jobsBodyData.qualificationRemark }} ) </span>
                  </div>
                  <div>
                    <p v-for="item, index in jobsBodyData.jobQualification" :key="index">{{ index + 1 + '、 ' + item }}</p>
                  </div>
                </div>


                <div class="detail-module-wrapper">
                  <div class="module-labei">
                    <span class="separator-span"></span>
                    <span class="label-title-span">工作地址:</span>
                  </div>
                  <div>
                    <p>上海市浦东新区合庆镇仁庆路309号</p>
                  </div>
                </div>



              </div>

              <div class="jobs-detail-footer">


              </div>
            </div>

          </div>
        </div>

      </div>
  
    </div>
  </template>
  
  <script>
  import { JobsLink } from "@/assets/js/submenulink.js";
  import { jobsData } from "@/api/jobs.js";
  import Submenu from "@/components/Submenu/";
  import ImageLoader from "@/components/ImageLoader/";
  import ImagePreview from "@/components/ImagePreview";
  export default {
     components: {
      Submenu,
      ImageLoader,
      ImagePreview,
     },
    
     data() {
      return {
        newsListHide: true,//招聘列表和主体部分的切换
        windowSize: {},
        jobsListHide: true,//切换列表和明细
        linkData: [],
        jobsData: [],//招聘信息
        checkData: [],//选中的数据
        jobsBodyData: "",//招聘主体数据
        workplace: [],
        workplace_list: [],//工作地点列表
        job_type: [],
        job_type_list: [],//职位类别
        job_title: '',
        job_title_list: [
          {"value":"软件工程师 ( 储备 )" },
          {"value":"硬件工程师 ( 储备 )" },
        ],//职位名称
       
        listSub: 0,//选中的招聘
        pageIndex: 1,
        pageSize: 8,
        img: require("@/assets/img/Jobs/jobs.png"),

      }
    },
    computed: {
      jobCount() {
        return this.jobsData.length;
      },
      
    },
  
    created() {
        this.linkData = JobsLink;
        this.getWindowSize();
        this.getJobsData();
     },
  
    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      // 得到招聘数据
      getJobsData() {
        jobsData().then(res => {
               this.jobsData = res;
               this.initJobClass();
               this.getCheckData();
            });
        },

      // 获取可视区窗口大小
      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           if( this.windowSize.width < 576) this.img = require("@/assets/img/Jobs/jobs2.png");
           else this.img = require("@/assets/img/Jobs/jobs.png");
        },

      //获取新闻主体数据
      getNewsBody(sub){
          this.listSub = sub;
          this.newsAbstract = this.newsList[sub];
          this.newsListHide = false;
      },

      toNewsList(){
        this.newsListHide = true;
      },

      getPreviewurl(item){
         return [item];
       },

      // 分页导航
       handlePageChange(val) {
          this.pageIndex = val;
          console.log(this.pageIndex);
        },

        //获取某个字段的不重复数据
        getKeyData(Arr, key){
           let arr = [];
           Arr.forEach( (ele ) => {
              if( arr.indexOf( ele[key] )  === -1) arr.push( ele[key] );
              
           })

           return arr;
        },


      initJobClass(){
         this.getWorkplaceList();
         this.getJobTypeList();
        //  this.getJobTitleList();
      },


        
        //获取工作地点列表
        getWorkplaceList() {
          this.workplace_list = this.getKeyData( this.jobsData, 'workplace');
      },


        //获取工作类型列表
        getJobTypeList() {
          this.job_type_list = this.getKeyData( this.jobsData, 'jobType');
      },


      queryJobTitle(queryString, cb){
        var restaurants = this.job_title_list;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        cb(results);
      },

      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },


      getCheckData(){
        const This = this;

        this.checkData = this.jobsData.filter( ( ele ) => {
          if ( (This.workplace == "" || This.workplace.indexOf(ele.workplace.toLowerCase())!= -1 ) && (This.job_type == "" || This.job_type.indexOf(ele.jobType.toLowerCase())!= -1 ) && (This.job_title == "" || ele.jobTitle.toLowerCase().indexOf(This.job_title.toLowerCase()) === 0 ))
          return true;
        })
      },

      //改变筛选条件
      changeJobType(){
         this.getCheckData();
      },

      getJobsBody(id){
        this.jobsListHide = false;
        this.jobsBodyData = (this.checkData.filter( (ele) => ele.id === id ))[0];
      },

      getEmaillHref(job_title){
       let hre = "mailto:jldz@ejlae.com" + "?subject=" + '';
       return hre;
      },

      clickRow(row){
        this.jobsListHide = false;
        this.jobsBodyData = row;
      }

     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
    .recruitment-information-container{
      position: relative;;
      width: 100%;;
      height: auto;
  
      .main-wrapper{
        position: relative;;
        width: 100%;
        margin-top: 3rem;
        padding: 0 3rem;
        height: auto;
        .tools-wrapper{
          // border:1px solid blue;
          margin-bottom:2rem;

          .job-search-wrapper{
            text-align: right;
          }

          .tools-label{
            font-weight: bold;
            margin-right: 1rem;
          }
          

          .jobs-type-wrapper{
            margin-left: 4rem;
          }

          p{
            display: inline-block;
          }
    
        }


        .job-vacancy-table{
          position: relative;
          margin-bottom: 3rem;

          .has-gutter tr{
            color:red
          }

          .el-table__row:hover{
            color:aqua;
          }

          a{
            margin-right: 1rem;
          }
        }



        .job-vacancy-main{
          position: relative;

          .jobs-detail-header{

             margin-bottom: 2rem;
            .job-qualification{
            margin: 2rem 0 1rem 0;
            ul{
              margin: 0;
              padding: 0;
            }

            li{
              list-style: none;
              display: inline-block;
              padding-right: 0.5rem;
              border-right: 2px solid @gray;
              margin-right: 0.5rem;

              &:last-child{
                border: none;
              }

            }
          }

          .social-benefits{
            // margin-bottom: 1rem;
            .el-tag{
              margin-right: 0.8rem;
            }
          }


          .job-application{
            text-align: end;
            a{
              margin-left: 1rem;
            }
          }
          }

          .jobs-detail-main{

            .detail-module-wrapper{
              padding: 1rem 0;
              border-top: 1px solid @gray;
              .separator-span{
              border: 2px solid orangered;
              margin-right: 1rem;
            }

            .label-title-span{
              font-size: 1.2rem;
              font-weight: 700;
            }

            .module-labei{
              margin-bottom: 1rem;
            }
            }


    
          }
          
        }
        
      }

              
      @media (max-width: 576px){
            .jobs-type-wrapper{
                margin-left: 0 !important;
                margin-top: 1rem !important;
              }

              .job-search-wrapper{
                text-align: start !important;
                margin-top: 1rem !important;
              }

              .job-application{
                text-align: start !important;
              }

              .main-wrapper{
                padding: 0 1rem !important;
              }

          }
     
        

    }
  
  </style>