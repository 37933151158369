<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-22 16:56:12
-->
<template>
    <div class="rdGroup-container">
  
      <div class="submenu-wrapper">
        <Submenu 
        :engLabel="englabel"
        :cnLabel="cnLabel"
        :linkData="linkData"
      />
      </div>
  
      <div class="main-wrapper py-2 py-md-5 px-2">
          <!-- 研发团队 -->
        <div class="img-wrapper">
          <p class="split-header">研发团队</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-8">
              <ImageLoader 
                :src="img"
                :height="imgHeight"
                fit="fill"
                />
            </div> 
         </div>
        </div>

        <div class="patent-wrapper ">

           <div>
                <p class="split-header">研发专利</p>
                <p class="patent-title article-paragraph">
                  参与国家安全气囊法规、防夹行业标准的起草工作。
                  - 拥有60项国家专利，包括6个发明专利，并且有12个专利未来将要授权。
                </p>
           </div>

            <div class="row justify-content-start justify-content-md-center mt-3">
                  <div class="col-12 col-md-4 col-lg-3 my-2 mx-1 mx-md-2" v-for="item in patentData" :key="item">
                    <div class="patent-img my-2 my-md-4">
                        <ImagePreview
                          :src="item"
                          :height="398"
                          :width="308"
                          :previewurl="getPreviewurl(item)"
                          fit="fill"
                        />
                    </div>
                </div>
             </div>

        </div>

        <!-- 研发质量 -->
        <div class="img-wrapper">
          <p class="split-header">研发质量</p>
          <div class="row justify-content-center">
            <div class="col-12 col-xl-8 ">
              <ImageLoader 
                :src="img2"
                :height="img2Height"
                fit="fill"
                />
            </div> 
         </div>
        </div>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import { AboutLink } from "@/assets/js/submenulink.js";
  import Submenu from "@/components/Submenu/";
  import ImageLoader from "@/components/ImageLoader/";
  import ImagePreview from "@/components/ImagePreview";
  export default {
     components: {
      Submenu,
      ImageLoader,
      ImagePreview,
     },
    
     data() {
      return {
        englabel: 'ABOUT US',
        cnLabel: '关于东久电子',
        imgHeight: 568,
        img_aspect_ratio: (1133 / 534).toFixed(2),

        img2Height: 568,
        img2_aspect_ratio: (792 / 360).toFixed(2),

        windowSize: {},
        linkData: [],
        patentData:[
            require("@/assets/img/About/yanfatuandui/patent1.png"),
            require("@/assets/img/About/yanfatuandui/patent2.png"),
            require("@/assets/img/About/yanfatuandui/patent3.png"),
            require("@/assets/img/About/yanfatuandui/patent4.png"),
            require("@/assets/img/About/yanfatuandui/patent5.png"),
            require("@/assets/img/About/yanfatuandui/patent6.png")
        ],
        img: require("@/assets/img/About/yanfatuandui/yanfatuandui.png"),
        img2: require("@/assets/img/About/yanfatuandui/quality.png"),


      }
    },
  
    created() {
        this.getWindowSize();
        this.linkData = AboutLink;
     },
  
  
    mounted() {
        //拉伸窗口时触发
        window.onresize = () => {
            this.getWindowSize();
            
        }
  
    },
     methods: {
      getPreviewurl(item){
         return [item];
       },
      getWindowSize() {
           this.windowSize.width =   document.documentElement.clientWidth || document.body.clientWidth; 
           this.windowSize.Height =  document.documentElement.clientHeight || document.body.clientHeight; 
           this.imgHeight =  Math.round(this.windowSize.width / this.img_aspect_ratio); 
           this.img2Height = Math.round(this.windowSize.width / this.img2_aspect_ratio); 
          },
     }
  
  
  }
  </script>
  
  <style scoped lang="less">
  @import "~@/styles/global.less";
    .rdGroup-container{
      position: relative;;
      width: 100%;;
      height: auto;
      //继承通用属性
      .general-layout;
  
        .patent-wrapper{
           margin: 4rem 0;
          .patent-title{
            text-align: center;
            font-size: 1.2rem;
          }

          @media (max-width: 576px){
            .patent-title{
                text-align: start;
                font-size: 0.9rem;
              }
          }

          
          .patent-img{
            width: 100%;
            text-align: center;
            .image-preview-container{
                border:1px solid @lightWords;
                display: inline-block;
            }
          }
           
        }
  
  

    }
  
  </style>