<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhouxh
 * @Date: 2023-05-30 16:44:12
-->
<template>
  <div class="menu-icon-container">
    <div class="mobile-only">
        <div 
            class="lines"
            :class="{'open-nav':!isMobileNavShow,'not-first':hasMobileNavOpened,'close-nav': isMobileNavShow}"
        >
            <i class="up"></i> 
            <i class="middle"></i> 
            <i class="down"></i>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobileNavShow: {
          type: Boolean,
          required: true,
        },

    hasMobileNavOpened: {
          type: Boolean,
          default:false,
    }

   },
}
</script>

<style scoped lang="less">
@import "~@/styles/global.less";

.mobile-only:hover{
          color:@hoverLink !important;
        }
.lines {
      
      i {
        display: block;
        width: 28px;
        height: 3px;
        margin-bottom: 6px;
        border-radius: 3px;
        background-color:@lightWords;

     
        &:last-child {
          margin-bottom: 0;
        }

        &.middle {
          width: 22px;
          transition: width 0.3s linear;
        }
      }

      &.not-first.open-nav {
        .up {
          animation: up-line 0.6s linear forwards reverse;
        }

        .middle {
          width: 0;
        }

        .down {
          animation: down-line 0.6s linear forwards;
        }
      }

  &.not-first.close-nav {
        .up {
          animation: up-line-reverse 0.6s linear ;
        }

        .down {
          animation: down-line-reverse 0.6s linear;
        }
      }  

        
      @keyframes up-line {
            0% {
              // transform: translateY(1.73333333vw) rotate(45deg);
              transform: translateY(2.3vw) rotate(45deg);
            }

            60% {
              transform: translateY(2.3vw);
            }

            100% {
              transform: translateY(0);
            }
          }




          @keyframes down-line {
            0% {
              transform: translateY(0);
            }

            40% {
              transform: translateY(-2.3vw);
            }

            100% {
              transform: translateY(-2.3vw) rotate(-45deg);
            }
          }



          @keyframes up-line-reverse {
            0% {
              transform: translateY(2.3vw) rotate(45deg);
            }

            60% {
              transform: translateY(2.3vw);
            }

            100% {
              transform: translateY(0);
            }
          }





          @keyframes down-line-reverse {
            0% {
              transform: translateY(-2.3vw) rotate(-45deg);
            }

            40% {
              transform: translateY(-2.3vw);
            }

            100% {
              transform: translateY(0);
            }
          }
    }
</style>